<template src="./fieldsPage.html"></template>

<script>
    import Vue from 'vue'

    export default {
        name: 'fields-page',
        props: [
            'pageData',
        ],
        data: function() {
            return {
                grdFieldsFields: [],
                grdFieldsItems: [],
                grdFieldsSortBy: {
                    columns: [
                        {field: 'FieldName', direction: 'Ascending'},
                    ]
                },
                grdFieldsClass: '',
                grdFieldsAllowPaging: true,
                grdFieldsPageSettings: {pageSize: 30},
                searchCropYear: '',
                cropYearOptions: [],
                searchFarmerName: '',
                farmerNameOptions: [],
                searchCounty: '',
                countyOptions: [],
                searchCropType: '',
                tags: [],
                tagOptions: [ { text: 'Untagged', value: 0 } ],
                cropTypeOptions: [],
                searchText: '',
                grdFieldsActions: [
                    {
                        text: 'Edit Programs/Projects',
                    },
                    {
                        text: 'Reassign Field'
                    },
                    {
                        text: 'Unlock Field',
                    },
                    {
                        text: 'View STAR Report'
                    },
                    {
                        text: 'View Improvement Plan'
                    },
                    {
                        text: 'Delete Field',
                    }
                ],
                grdFieldsReassignedActions: [
                    {
                        text: 'View Assignment'
                    },
                    {
                        text: 'View STAR Report'
                    },
                    {
                        text: 'View Improvement Plan'
                    },
                    {
                        text: 'Delete Field',
                    }
                ],
                selectedRow: null,
                totalNumberOfFields: 0,
                totalNumberOfAcres: 0,
                totalNumberOfFarmers: 0
            }
        },
        computed: {
            ShowAddField() {
                if (app.signedInUser.UserType !== 'Licensee') {
                    return true;
                }
                else {
                    return false;
                }
            },
            showAddFieldScoresheet() {
                if (app.signedInUser.UserType !== 'Licensee') {
                    return true;
                }
                else {
                    return false;
                }
            },
            userIsAdminOrLicensee() {
                return app.signedInUser.UserType === 'Admin' || app.signedInUser.UserType === 'Licensee';
            }
        },
        methods: {
            getFields() {
                DM.http({
                    method: 'POST',
                    url: '/FieldAssignments/GetAllForAllUsers',
                    data: {
                        CropYear: this.searchCropYear || 0,
                        CropID: this.searchCropType || 0,
                        UserID: this.searchFarmerName || 0,
                        CountyID: this.searchCounty || 0,
                        TextSearch: this.searchText,
                        TagIDs: this.tags
                    }
                }).then(response => {
                    response.forEach(f => {
                        f.Tags = f.Tags.map(t => t.TagName.replaceAll(' ', '&nbsp;')).join(', ');
                    });

                    this.totalNumberOfFields = DM.displayNumber(response.length, 0);
                    let totalNumberOfAcres = 0;
                    let farmerIDs = [];
                    response.forEach(f => {
                        f.FieldAssignment = f.HasBeenReassigned ? 'Reassigned' : f.FieldAssignmentID;
                        totalNumberOfAcres += f.FieldAcres;

                        if(!farmerIDs.includes(f.UserID)) {
                            farmerIDs.push(f.UserID);
                        }
                    });
                    this.totalNumberOfAcres = DM.displayNumber(totalNumberOfAcres, 0);
                    this.totalNumberOfFarmers = DM.displayNumber(farmerIDs.length, 0);
                    this.grdFieldsItems = response;
                });
            },
            getFarmerOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Farmers/GetAll',
                }).then(response => {
                    this.farmerNameOptions = response.map(c => {
                        return {value: c.UserID, text: c.FarmerName}
                    });
                });
            },
            getCountyOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Counties/GetAll',
                }).then(response => {
                    this.countyOptions = response.map(c => {
                        return {value: c.CountyID, text: c.DisplayText}
                    });
                });
            },
            getCropTypeOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Crops/GetAll',
                }).then(response => {
                    this.cropTypeOptions = response.map(c => {
                        return {value: c.CropID, text: c.CropName}
                    });
                });
            },
            getCropYearOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Crops/GetAllYears',
                }).then(response => {
                    this.cropYearOptions = response.CropYears.map(y => {
                        return {value: y, text: y}
                    });
                });
            },
            grdFieldsRowOnClick(args) {
                this.selectedRow = args;
            },
            grdFieldsActionOnClick(args) {
                if (args.item.text === 'Edit Programs/Projects') {
                    eventBus.$emit('showAppModal', this, 'fieldTagsEditModal', {
                        mode: 'edit',
                        field: this.selectedRow.rowData,
                        callback: this.getFields,
                    });
                }

                if (args.item.text === 'Reassign Field') {
                    eventBus.$emit('showAppModal', this, 'fieldsAssignmentEditModal', {
                        mode: 'edit',
                        field: this.selectedRow.rowData,
                        callback: this.getFields
                    });
                }

                if (args.item.text === 'Unlock Field') {
                    const confirmCallback = () => {
                        const fieldAssignment = this.selectedRow.rowData;
                        this.unlockFieldAssignment(fieldAssignment);
                    }

                    DM.confirmShow('Taking this action will delete the STAR report and Improvement Plan. Are you sure you want to continue?', 'Unlock Field', confirmCallback, null, 'Yes', 'No');
                }

                if (args.item.text === 'View Assignment') {
                    eventBus.$emit('showAppModal', this, 'fieldsAssignmentEditModal', {
                        mode: 'view',
                        field: this.selectedRow.rowData,
                        callback: () => {

                        }
                    });
                }

                if (args.item.text === 'View STAR Report') {
                    this.getPDF('/StarScores/DownloadReport');
                }

                if (args.item.text === 'View Improvement Plan') {
                    this.getPDF('/ImprovementPlans/DownloadReport');
                }

                if (args.item.text === 'Delete Field') {
                    const confirmCallback = () => {
                        const fieldAssignment = this.selectedRow.rowData;
                        this.deleteFieldAssignment(fieldAssignment);
                    }

                    DM.confirmShow('Taking this action will delete the field for the selected crop year. Are you sure you want to continue?', 'Delete Field', confirmCallback, null, 'Yes', 'No');
                }
            },
            searchFieldsOnClick() {
                this.getFields();
            },
            addFieldScoresheetOnClick() {
                eventBus.$emit('showAppModal', this, 'fieldsUploadScoresheetModal', {
                    mode: 'upload',
                    callback: this.getFields
                });
            },
            addFieldOnClick() {
                eventBus.$emit('showAppModal', this, 'fieldsUploadModal', {
                    mode: 'upload',
                    callback: this.getFields
                });
            },
            unlockFieldAssignment(fieldAssignment) {
                DM.http({
                    method: 'POST',
                    data: fieldAssignment,
                    url: '/FieldAssignments/Unlock',
                }).then(() => {
                    this.getFields();
                });
            },
            deleteFieldAssignment(fieldAssignment) {
                DM.http({
                    method: 'POST',
                    data: fieldAssignment,
                    url: '/FieldAssignments/DeleteAdmin',
                }).then(() => {
                    this.getFields();
                });
            },
            getPDF(url) {
                DM.addRequest();
                axios({
                    method: 'GET',
                    url: window.apiRoot + url,
                    params: {
                        FieldAssignmentID: this.selectedRow.rowData.FieldAssignmentID
                    },
                    headers: {Authorization: 'Bearer ' + DM.authToken},
                    responseType: 'arraybuffer'
                })
                    .then(response => {
                        let content = response.headers['content-disposition'];
                        let filename = content.split(';')[1].replace('filename=', '').replace('"', '').replace('"', '').trim();
                        this.downloadAjaxResponseAsFile(response, filename)
                    })
                    .catch((response) => {
                        try {
                            response.data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(response.response.data)));
                        } catch (err) {

                        }

                        let errorString = response.data.Error ? response.data.Error : "An unexpected error has occurred on the server.";
                        DM.alertShow(errorString, 'Error');
                    })
                    .finally(() => {
                        DM.removeRequest();
                    });
            },
            downloadAjaxResponseAsFile(response, fileName) {
                let blob = new Blob([response.data], {type: 'application/pdf'});
                let objectUrl = URL.createObjectURL(blob);
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.href = objectUrl;
                a.download = fileName;
                a.click();
                document.body.removeChild(a);
            },
            showDisclaimer() {
                let message = 'STAR Reports can be downloaded by selecting the vertical ellipsis menu' +
                              ' next to each field and choosing \'View STAR Report\'.'
                DM.alertShow(message, '', () => {
                    DM.localStorageSet('fieldsDisclaimer.StarPwaAdminLicensee', true);
                });
            },
            getTagOptions() {
                return DM.http({
                    method: 'GET',
                    url: '/Tags/GetAll'
                }).then(response => {
                    response.forEach(tag => {
                        let formattedOption = { text: tag.TagName, value: tag.TagID };
                        this.tagOptions.push(formattedOption);
                    });
                });
            },
        },
        created() {
            let hasSeenFieldsDisclaimer = DM.localStorageGet('fieldsDisclaimer.StarPwaAdminLicensee');
            if(!hasSeenFieldsDisclaimer) {
                this.showDisclaimer();
            }

            if(this.pageData && this.pageData.userID) {
                this.searchFarmerName = this.pageData.userID;
            }

            this.getFields();
            this.getCountyOptions()
            this.getFarmerOptions();
            this.getCropTypeOptions();
            this.getCropYearOptions();
            this.getTagOptions();

            let self = this;

            this.grdFieldsFields = [
                {
                    type: 'template',
                    width: '80px',
                    template: function() {
                        return {
                            template: Vue.component('columnTemplate', {
                                template: `<ejs-dropdownbutton
                                                :items="grdFieldsActions"
                                                :select="select"
                                                iconCss="e-icons e-ellipsis-vert"
                                                cssClass="e-caret-hide">
                                           </ejs-dropdownbutton>`,
                                data: function() {
                                    return {}
                                },
                                methods: {
                                    select(args) {
                                        self.grdFieldsActionOnClick(args);
                                    }
                                },
                                computed: {
                                    grdFieldsActions: function() {
                                        let actions = this.data.FieldAssignmentID === 'Reassigned' ? self.grdFieldsReassignedActions : self.grdFieldsActions;
                                        if(this.data.StarScore === 0) {
                                            actions = actions.filter(a => a.text !== 'View STAR Report');
                                        }

                                        if(!this.data.HasImprovementPlan) {
                                            actions = actions.filter(a => a.text !== 'View Improvement Plan');
                                        }

                                        return actions;
                                    },
                                }
                            })
                        }
                    }
                },
                {
                    key: 'FieldName',
                    label: 'Field Name',
                    allowSorting: true,
                    width: '150px'
                },
                {
                    key: 'FarmerName',
                    label: 'Farmer Name',
                    allowSorting: true,
                    width: '250px'
                },
                {
                    key: 'CropType',
                    label: 'Crop Type',
                    allowSorting: true,
                    width: '100px'
                },
                {
                    key: 'FieldAssignment',
                    label: 'Assignment ID',
                    allowSorting: true,
                    width: '120px'
                },
                {
                    key: 'CountyName',
                    label: 'County',
                    allowSorting: true,
                    width: '200px'
                },
                {
                    key: 'CropYear',
                    label: 'Crop Year',
                    allowSorting: true,
                    width: '100px'
                },
                {
                    key: 'StarScore',
                    label: 'STAR Rating',
                    width: '110px',
                    textAlign: 'Center',
                    allowSorting: true
                },
                {
                    key: 'FieldPracticesScore',
                    label: 'STAR Score',
                    width: '110px',
                    textAlign: 'Center',
                    allowSorting: true
                },
                {
                    key: 'HasImprovementPlan',
                    label: 'Improvement Plan',
                    width: '150px',
                    type: 'custom',
                    formatter: (field, data) => {
                        return data.HasImprovementPlan ? 'Y' : 'N';
                    },
                    textAlign: 'Center',
                },
                {
                    key: 'Tags',
                    label: 'Program/Project',
                    width: '200px',
                    allowSorting: true
                },
                {
                    key: 'WasUploaded',
                    label: 'Uploaded',
                    width: '150px',
                    type: 'custom',
                    formatter: (field, data) => {
                        return data.WasUploaded ? 'Y' : '';
                    },
                    textAlign: 'Center',
                    allowSorting: true
                },
            ];
        },
        mounted() {

        }
    }
</script>
