<template src="./usersPage.html"></template>

<script>
    import Vue from 'vue'

    export default {
        name: 'users-page',
        data: function () {
            return {
                grdUsersFields: [],
                grdUsersItems: [],
                grdUsersSortBy: {
                    columns: [
                        {field: 'LastName', direction: 'Ascending'},
                    ]
                },
                grdUsersClass: '',
                searchUserType: '',
                userTypeOptions: [],
                searchCounty: '',
                countyOptions: [],
                searchOrganization: '',
                organizationOptions: [],
                searchText: '',
                grdUsersActions: [
                    {
                        text: 'Edit'
                    }
                ],
                selectedRow: null
            }
        },
        methods: {
            getUsers() {
                DM.http({
                    method: 'GET',
                    url: '/Users/GetAll',
                    params: {
                        Type: this.searchUserType,
                        CountyID: this.searchCounty,
                        Organization: this.searchOrganization,
                        TextSearch: this.searchText,
                    }
                }).then(response => {
                    response.forEach(u => {
                        u.countyNames = this.countyFilter(u);
                    });
                    this.users = response;
                    this.grdUsersItems = response;
                });
            },
            getUserTypeOptions() {
                this.userTypeOptions = [
                    {
                        text: 'Admin',
                        value: 'Admin'
                    },
                    {
                        text: 'Licensee',
                        value: 'Licensee'
                    },
                    {
                        text: 'Farmer',
                        value: 'Farmer'
                    },
                    {
                        text: 'Verifier',
                        value: 'Verifier'
                    }
                ];
            },
            grdUsersRowOnClick(args) {
                this.selectedRow = args;
            },
            addUserOnClick() {
                eventBus.$emit('showAppModal', this, 'usersEditModal', {
                    mode: "new",
                    user: {},
                    userTypeOptions: this.userTypeOptions,
                    countyOptions: this.countyOptions,
                    callback: this.getUsers
                });
            },
            grdUsersActionOnClick(args) {
                if(args.item.text === 'Edit') {
                    this.getUserAndOpenEditModal();
                }
            },
            getUserAndOpenEditModal() {
                DM.http({
                    method: "GET",
                    url: "/Users/GetByID",
                    params: {
                        UserID: this.selectedRow.rowData.UserID
                    }
                })
                    .then(response => {
                        eventBus.$emit('showAppModal', this, 'usersEditModal', {
                            mode: "edit",
                            user: response,
                            userTypeOptions: this.userTypeOptions,
                            countyOptions: this.countyOptions,
                            callback: (user) => {
                                if(this.selectedRow.rowData.UserID === app.signedInUser.UserID) {
                                    eventBus.$emit('setSignedInUser', user);
                                }
                                this.getUsers();
                            }
                        });
                    });
            },
            searchUsersOnClick() {
                this.getUsers();
            },
            getCountyOptions() {
                return DM.http({
                    method: "GET",
                    url: "/Counties/GetAll",
                }).then(response => {
                    response.forEach(type => {
                        let dataObject = {text: type.DisplayText, value: type.CountyID};
                        this.countyOptions.push(dataObject);
                    });
                });
            },
            getOrganizationOptions() {
                return DM.http({
                    method: "GET",
                    url: "/Organizations/GetAll",
                }).then(response => {
                    response.Organizations.forEach(org => {
                        let dataObject = {text: org, value: org};
                        this.organizationOptions.push(dataObject);
                    });
                });
            },
            countyFilter(data) {
                // Note: Filter out any unsupported counties to prevent table row insertion from breaking -- Andrew 12/28/2021

                let validCountyIDs = [];
                data.CountyIDs.map(c => {
                    if (this.countyOptions.filter(o => o.value === c).length > 0) {
                        validCountyIDs.push(c);
                    }
                });

                data.CountyIDs = validCountyIDs;

                return data.CountyIDs.map(c => this.countyOptions.filter(o => c === o.value)[0].text).join(', ');
            },
        },
        created() {
            this.getCountyOptions()
                .then(response => {
                    this.getUsers();
                });
            this.getOrganizationOptions();
            this.getUserTypeOptions();

            let self = this;

            this.grdUsersFields = [
                {
                    type: 'template',
                    width: '80px',
                    template: () => {
                        return {
                            template: Vue.component('columnTemplate', {
                                template: `<ejs-dropdownbutton
                                                :items="grdUsersActions"
                                                :select="select"
                                                iconCss="e-icons e-ellipsis-vert"
                                                cssClass= "e-caret-hide">
                                           </ejs-dropdownbutton>`,
                                data: function() {
                                    return {
                                        grdUsersActions: self.grdUsersActions
                                    }
                                },
                                methods: {
                                    select(args) {
                                        self.grdUsersActionOnClick(args);
                                    }
                                }
                            })
                        }
                    }
                },
                {
                    key: "LastName",
                    label: "Last Name",
                    allowSorting: true,
                    width: '100px'
                },
                {
                    key: "FirstName",
                    label: "First Name",
                    allowSorting: true,
                    width: '100px'
                },
                {
                    key: "Type",
                    label: "Type",
                    allowSorting: true,
                    width: '80px'
                },
                {
                    key: "EmailAddress",
                    label: "Email",
                    allowSorting: true,
                    width: '300px'
                },
                {
                    key: "countyNames",
                    label: "County(ies)",
                    allowSorting: true,
                    minWidth: '200px'
                },
                {
                    key: "Organization",
                    label: "Organization",
                    allowSorting: true,
                    width: '150px'
                },
                {
                    key: "IsInactive",
                    label: "Active",
                    formatter: (field, data) => {
                        return data.IsInactive ? 'N' : 'Y';
                    },
                    width: '70px',
                    textAlign: 'Center',
                    allowSorting: false
                },
            ];
        },
        mounted() {

        }
    }
</script>
